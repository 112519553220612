import { render, staticRenderFns } from "./attendance-stats.vue?vue&type=template&id=3a8cc775&scoped=true"
import script from "./attendance-stats.vue?vue&type=script&lang=js"
export * from "./attendance-stats.vue?vue&type=script&lang=js"
import style0 from "./attendance-stats.vue?vue&type=style&index=0&id=3a8cc775&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a8cc775",
  null
  
)

export default component.exports