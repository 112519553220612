<template>
    <!-- 管理员- 考勤统计 -->
    <div class="course wrapper">
        <proTitle></proTitle>
        <div class="switch">
            <div class="switch-btn-warpper" :class="{'active':activeSwitch == item.id}"
                v-for="(item,index) in [{name:'跟岗考勤',id:0},{name:'住宿考勤',id:2}]" :key="index"
                @click="switchType(item.id)">
                <button class="csp switch-btn">
                    {{item.name}}
                </button>
            </div>
        </div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
            <el-form-item label="">
            </el-form-item>
            <div class="download-inline">
                <span class="export-btn csp" style="margin-right:10px" @click="exportFile">
                    <i class="export-icon"></i>总计导出</span>
                <span class="export-btn csp" style="margin-right:10px" @click="attendanceExportPath">
                    <i class="export-icon"></i>按人导出</span>
            </div>
        </el-form>
        <div class="table_section">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="number" label="序号" width="80" align="center" type="index" :index="indexMethod">
                </el-table-column>
                <el-table-column prop="dateString" label="日期" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="totalCount" label="项目总人数" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="attendanceCount" label="已签到人数" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="supplementCount" label="补签人数" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="unAttendanceCount" label="未签到人数" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="120">
                    <template slot-scope="scope">
                        <el-button @click="toDetail(scope.row)" type="text" size="small">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                :pageSizes="[10,20,30]" @pagination="handlePageChange">
            </pagination>
        </div>
    </div>

</template>

<script>
    import upload from './components/upload-file.vue';

    export default {
        components: {
            upload,
        },
        data() {
            return {
                tableData: [],
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                formInline: {
                    datetimerange: '',
                    search: '',
                },
                attendanceType: 0,
                activeSwitch: 0,
                loadingInstance: '',
                interval: null,
            };
        },
        methods: {
            // 序号
            indexMethod(index) {
                return this.pages.currentPage > 1 ?
                    (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
                    index + 1
            },
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getAttendanceList();
            },
            // 去考勤详情
            toDetail(row) {
                this.$router.push({
                    path: `/attendanceDetail`,
                    query: {
                        date: JSON.stringify(row.date),
                        type: this.activeSwitch,
                        breadNumber: 4,
                    }
                })

            },
            filterChange() {
                this.pages.currentPage = 1;
                this.getAttendanceList();
            },
            // 总计导出
            async exportFile() {
                this.$message.info('正在导出...')
                let id = this.$store.state.user.currentProject.id
                window.location.href =
                    `${this.commonbaseURL}/learn/attendance/listByAdmin/export?projectId=${id}&type=${this.attendanceType}`;
            },
            // 按人导出 获取打包地址
            async attendanceExportPath() {
                let pramas = {
                    projectId: this.$store.state.user.currentProject.id,
                    type: this.attendanceType,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                }
                let resData = await this.$Api.Project.attendanceExportPath(pramas)
                console.log(resData);
                if (resData.code == 200) {
                    this.loadingInstance = this.$loading({
                        lock: true,
                        text: '数据打包中...  请您耐心等待。',
                        spinner: 'el-icon-loading',
                    });
                    this.interval = setInterval(() => {
                        this.attendanceExportState(pramas)
                    }, 3000)
                } else {
                    console.log(resData.msg)
                    this.$message.error('打包失败，请联系管理员')
                }
            },
            // 查询打包状态
            async attendanceExportState(pramas) {
                let resData = await this.$Api.Project.attendanceExportState(pramas)
                if (resData.code == 200 && resData.data.state) {
                    // console.log('打包成功');
                    clearInterval(this.interval);
                    if (this.loadingInstance) {
                        this.loadingInstance.close()
                    }
                    this.getDownloadUrl(resData.data.identity);
                } else {
                    clearInterval(this.interval);
                    this.loadingInstance.close()
                    this.$message.error('打包失败，请联系管理员')
                }
            },
            // 获取打包好的文件地址
            async getDownloadUrl(identity) {
                let pramas = {
                    identifier: identity,
                }
                let resData = await this.$Api.Home.getDownloadUrl(pramas);
                // console.log('地址', resData);
                if (resData.data.url) {
                    window.location.href = resData.data.url;
                }
            },
            switchType(id) {
                console.log(id);
                this.activeSwitch = id;
                this.attendanceType = id;
                this.pages.currentPage = 1;
                this.getAttendanceList();
            },
            // 获取周报列表
            async getAttendanceList() {
                let pramas = {
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    projectId: this.$store.state.user.currentProject.id,
                    type: this.attendanceType,
                }
                let resData = await this.$Api.Project.getAttendanceList(pramas);
                // console.log(resData.data);
                this.tableData = resData.data.records
                this.pages.total = resData.data.total
            },

        },
        mounted() {
            this.getAttendanceList();
        },
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .course {
        padding: 20px 25px;
        box-sizing: border-box;

        .switch {
            .switch-btn-warpper {
                height: 42px;
                display: inline-block;
                margin-right: 10px;
            }

            .switch-btn {
                padding: 0 31px;
                font-size: 15px;
                line-height: 36px;
            }

            .active {
                background-image: url("~@/assets/images/admin/course-switch.png");
                background-size: 100% 100%;

                .switch-btn {
                    color: #508ef9;
                }
            }
        }

        .demo-form-inline {
            margin: 15px 0;

            .date-picker {
                width: 320px;
            }

            @media screen and (min-width:1250px) {
                margin: 20px 0;

                .date-picker {
                    width: 400px;
                }
            }
        }

        .download-inline {
            .export-btn {
                color: #595959;
            }

            .export-icon {
                display: inline-block;
                background-image: url("~@/assets/images/admin/export.png");
                background-size: 100% 100%;
                width: 17px;
                height: 16px;
                margin-right: 8px;
                vertical-align: -2px;
            }
        }


        .table_section {
            /deep/.el-table th {
                &>.cell {
                    white-space: pre-line;
                }
            }
        }

    }
</style>